import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { useMediaQuery } from 'react-responsive'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import { formatDollars } from '../../../utils/formatting'

import {
  boardCompensation,
  leadershipCompensation
} from '../../../utils/compensations'

const CompPage = () => {
  const intl = useIntl()
  const langCode = intl.locale
  const isScreenSm = useMediaQuery({ maxWidth: 1000 })

  return (
    <Layout
      subheader={<FormattedMessage id='nav.about' />}
      header={
        <span className='bold'>
          <FormattedMessage id='nav.compensation' />
        </span>
      }
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.compensation' })}
      />
      <Container>
        <Row>
          <Col md={12}>
            <p>
              <FormattedMessage id='compensation.contentAi' />{' '}
              <a href={intl.formatMessage({ id: 'compensation.linkA' })}>
                <FormattedMessage id='compensation.contentAii' />
              </a>
              <FormattedMessage id='compensation.contentAiii' />
            </p>

            <h3>
              <FormattedMessage id='compensation.headerA' />
            </h3>
            {isScreenSm ? (
              <>
                {boardCompensation.compensations.map((position, i) => (
                  <div className='tile'>
                    <h4>
                      <FormattedMessage id={position.position} />
                    </h4>
                    <ul>
                      <li>
                        <b>
                          <FormattedMessage id='compensation.tableHdrB' />:
                        </b>{' '}
                        {position.number}
                      </li>
                      <li>
                        <b>
                          <FormattedMessage id='compensation.tableHdrC' /> ($):
                        </b>{' '}
                        {formatDollars(position.perDiem, langCode)}
                      </li>
                      <li>
                        <b>
                          <FormattedMessage id='compensation.tableHdrD' /> ($):
                        </b>{' '}
                        {formatDollars(position.totalComp, langCode)}
                      </li>
                      <li>
                        <b>
                          <FormattedMessage id='compensation.tableHdrE' /> (%)
                        </b>{' '}
                        {position.fraction}%
                      </li>
                    </ul>
                  </div>
                ))}
                <div className='tile'>
                  <h4>Total</h4>
                  <ul>
                    <li>
                      <b>
                        <FormattedMessage id='compensation.tableHdrC' /> ($):
                      </b>{' '}
                      {formatDollars(boardCompensation.perDiemTotal, langCode)}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage id='compensation.tableHdrD' /> ($):
                      </b>{' '}
                      {formatDollars(boardCompensation.totalComp, langCode)}
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id='compensation.tableHdrA' />
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrB' />
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrC' /> ($)
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrD' /> ($)
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrE' /> (%)
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {boardCompensation.compensations.map((position, i) => (
                    <tr>
                      <td>
                        <FormattedMessage id={position.position} />
                      </td>
                      <td>{position.number}</td>
                      <td>{formatDollars(position.perDiem, langCode)}</td>
                      <td>{formatDollars(position.totalComp, langCode)}</td>
                      <td>{position.fraction}%</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ fontWeight: `bold` }}>TOTAL</td>
                    <td></td>
                    <td style={{ fontWeight: `bold` }}>
                      {formatDollars(boardCompensation.perDiemTotal, langCode)}
                    </td>
                    <td style={{ fontWeight: `bold` }}>
                      {formatDollars(boardCompensation.totalComp, langCode)}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            )}
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col md={12}>
            <h3>
              <FormattedMessage id='compensation.headerB' />
            </h3>

            {isScreenSm ? (
              <>
                {leadershipCompensation.roles.map((position, i) => (
                  <>
                    <div className='tile'>
                      <h4>
                        <FormattedMessage id={position.position} />{' '}
                        {position.footnote && (
                          <sup
                            style={{ fontStyle: `italic`, fontSize: `.8rem` }}
                          >
                            {position.footnote}
                          </sup>
                        )}
                      </h4>
                      <ul>
                        <li>
                          <b>
                            <FormattedMessage id='compensation.tableHdrF' />:
                          </b>{' '}
                          {position.class}
                        </li>
                        <li>
                          <b>
                            <FormattedMessage id='compensation.tableHdrB' />:
                          </b>{' '}
                          {position.listings[0].number}
                        </li>
                        <li>
                          <b>
                            <FormattedMessage id='compensation.tableHdrG' />{' '}
                            ($):
                          </b>{' '}
                          {formatDollars(
                            position.listings[0].cashComp,
                            langCode
                          )}
                        </li>
                        <li>
                          <b>
                            <FormattedMessage id='compensation.tableHdrH' />{' '}
                            ($):
                          </b>{' '}
                          {position.listings[0].pension
                            ? formatDollars(
                                position.listings[0].pension,
                                langCode
                              )
                            : '---'}
                        </li>
                        <li>
                          <b>
                            <FormattedMessage id='compensation.tableHdrD' />{' '}
                            ($):
                          </b>{' '}
                          {formatDollars(
                            position.listings[0].totalComp,
                            langCode
                          )}
                        </li>
                        <li>
                          <b>
                            <FormattedMessage id='compensation.tableHdrE' />{' '}
                            (%):
                          </b>{' '}
                          {position.listings[0].fraction}%
                        </li>
                      </ul>
                    </div>

                    {position.listings.length > 1 &&
                      position.listings.slice(1).map((listing, i) => (
                        <div className='tile'>
                          <h4>
                            <FormattedMessage id={position.position} />{' '}
                            {position.footnote && (
                              <sup
                                style={{
                                  fontStyle: `italic`,
                                  fontSize: `.8rem`
                                }}
                              >
                                {position.footnote}
                              </sup>
                            )}
                          </h4>
                          <ul>
                            <li>
                              <b>
                                <FormattedMessage id='compensation.tableHdrF' />
                                :
                              </b>{' '}
                              {position.class}
                            </li>
                            <li>
                              <b>
                                <FormattedMessage id='compensation.tableHdrB' />
                                :
                              </b>{' '}
                              {listing.number}
                            </li>
                            <li>
                              <b>
                                <FormattedMessage id='compensation.tableHdrG' />{' '}
                                ($):
                              </b>{' '}
                              {formatDollars(listing.cashComp, langCode)}
                            </li>
                            <li>
                              <b>
                                <FormattedMessage id='compensation.tableHdrH' />{' '}
                                ($):
                              </b>{' '}
                              {listing.pension
                                ? formatDollars(listing.pension, langCode)
                                : '---'}
                            </li>
                            <li>
                              <b>
                                <FormattedMessage id='compensation.tableHdrD' />{' '}
                                ($):
                              </b>{' '}
                              {formatDollars(listing.totalComp, langCode)}
                            </li>
                            <li>
                              <b>
                                <FormattedMessage id='compensation.tableHdrE' />{' '}
                                (%):
                              </b>{' '}
                              {listing.fraction}%
                            </li>
                          </ul>
                        </div>
                      ))}
                  </>
                ))}
                <div className='tile'>
                  <h4>Total</h4>
                  <ul>
                    <li>
                      <b>
                        <FormattedMessage id='compensation.tableHdrG' /> ($):
                      </b>{' '}
                      {formatDollars(
                        leadershipCompensation.cashTotal,
                        langCode
                      )}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage id='compensation.tableHdrH' /> ($):
                      </b>{' '}
                      {formatDollars(
                        leadershipCompensation.pensionTotal,
                        langCode
                      )}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage id='compensation.tableHdrD' /> ($):
                      </b>{' '}
                      {formatDollars(
                        leadershipCompensation.totalComp,
                        langCode
                      )}
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id='compensation.tableHdrA' />
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrF' />
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrB' />
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrG' /> ($)
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrH' /> ($)
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrD' /> ($)
                    </th>
                    <th>
                      <FormattedMessage id='compensation.tableHdrE' /> (%)
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {leadershipCompensation.roles.map((position, i) => (
                    <>
                      <tr>
                        <td rowSpan={position.listings.length}>
                          <FormattedMessage id={position.position} />{' '}
                          {position.footnote && (
                            <sup
                              style={{ fontStyle: `italic`, fontSize: `.8rem` }}
                            >
                              {position.footnote}
                            </sup>
                          )}
                        </td>
                        <td rowSpan={position.listings.length}>
                          {position.class}
                        </td>
                        <td>{position.listings[0].number}</td>
                        <td>
                          {formatDollars(
                            position.listings[0].cashComp,
                            langCode
                          )}
                        </td>
                        <td>
                          {position.listings[0].pension
                            ? formatDollars(
                                position.listings[0].pension,
                                langCode
                              )
                            : '---'}
                        </td>
                        <td>
                          {formatDollars(
                            position.listings[0].totalComp,
                            langCode
                          )}
                        </td>
                        <td>{position.listings[0].fraction}%</td>
                      </tr>
                      {position.listings.length > 1 &&
                        position.listings.slice(1).map((listing, i) => (
                          <tr>
                            <td>{listing.number}</td>
                            <td>{formatDollars(listing.cashComp, langCode)}</td>
                            <td>
                              {listing.pension
                                ? formatDollars(listing.pension, langCode)
                                : '---'}
                            </td>
                            <td>
                              {formatDollars(listing.totalComp, langCode)}
                            </td>
                            <td>{listing.fraction}%</td>
                          </tr>
                        ))}
                    </>
                  ))}

                  <tr>
                    <td style={{ fontWeight: `bold` }}>TOTAL</td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: `bold` }}>
                      {formatDollars(
                        leadershipCompensation.cashTotal,
                        langCode
                      )}
                    </td>
                    <td style={{ fontWeight: `bold` }}>
                      {formatDollars(
                        leadershipCompensation.pensionTotal,
                        langCode
                      )}
                    </td>
                    <td style={{ fontWeight: `bold` }}>
                      {formatDollars(
                        leadershipCompensation.totalComp,
                        langCode
                      )}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            )}

            <ul style={{ listStyle: `none`, paddingLeft: `0` }}>
              <li className='footnote'>
                <sup>1</sup> <FormattedMessage id='compensation.footnoteB' />
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default CompPage
