export const boardCompensation = {
  perDiemTotal: '104,750.00',
  totalComp: '104,750.00',

  compensations: [
    {
      position: 'compensation.positionA',
      number: 1,
      perDiem: '37,000.00',
      totalComp: '37,000.00',
      fraction: '100.0'
    },
    {
      position: 'compensation.positionB',
      number: 1,
      perDiem: '13,125.00',
      totalComp: '13,125.00',
      fraction: '100.0'
    },
    {
      position: 'compensation.positionC',
      number: 1,
      perDiem: '13,875.00',
      totalComp: '13,875.00',
      fraction: '100.0'
    },
    {
      position: 'compensation.positionC',
      number: 2,
      perDiem: '8,500.00',
      totalComp: '8,500.00',
      fraction: '100.0'
    },
    {
      position: 'compensation.positionC',
      number: 3,
      perDiem: '8,500.00',
      totalComp: '8,500.00',
      fraction: '100.0'
    },
    {
      position: 'compensation.positionC',
      number: 4,
      perDiem: '7,500.00',
      totalComp: '7,500.00',
      fraction: '100.0'
    },
    {
      position: 'compensation.positionC',
      number: 5,
      perDiem: '6,500.00',
      totalComp: '6,500.00',
      fraction: '100.0'
    },
    {
      position: 'compensation.positionC',
      number: 6,
      perDiem: '6,250.00',
      totalComp: '6,250.00',
      fraction: '100.0'
    },
    {
      position: 'compensation.positionC',
      number: 7,
      perDiem: '2,000.00',
      totalComp: '2,000.00',
      fraction: '25.0'
    },
    {
      position: 'compensation.positionC',
      number: 7,
      perDiem: '1,500.00',
      totalComp: '1,500.00',
      fraction: '33.0'
    }
  ]
}

export const leadershipCompensation = {
  cashTotal: '1,586,705.66',
  pensionTotal: '58,272.95',
  totalComp: '1,644,978.61',

  roles: [
    {
      position: 'compensation.positionD',
      // footnote: 1,
      class: 1,
      listings: [
        {
          number: 1,
          cashComp: '286,463.15',
          pension: '718.76',
          totalComp: '287,181.91',
          fraction: '100.0'
        }
      ]
    },

    {
      position: 'compensation.positionE',
      footnote: 1,
      class: 5,
      listings: [
        {
          number: 1,
          cashComp: '240,215.0',
          pension: '11,760.46',
          totalComp: '251,975.50',
          fraction: '100.0'
        },
        {
          number: 2,
          cashComp: '253,784.04',
          pension: '12,276.95',
          totalComp: '266,060.99',
          fraction: '100.0'
        },
        {
          number: 3,
          cashComp: '216,488.45',
          pension: '10,499.44',
          totalComp: '226,987.89',
          fraction: '100.0'
        },
        {
          number: 4,
          cashComp: '162,682.84',
          pension: '7,950.45',
          totalComp: '170,633.29',
          fraction: '100.0'
        },
        {
          number: 5,
          cashComp: '175,231.88',
          pension: '8,494.77',
          totalComp: '183,726.65',
          fraction: '100.0'
        },
        {
          number: 6,
          cashComp: '130,972.17',
          pension: '3,587.61',
          totalComp: '134,559.78',
          fraction: '58.0'
        },
        {
          number: 6,
          cashComp: '63,945.00',
          pension: '2,984.51',
          totalComp: '66,929.51',
          fraction: '42.0'
        },
        {
          number: 7,
          cashComp: '56,923.09',
          pension: '0.00',
          totalComp: '56,923.09',
          fraction: '34.0'
        }
      ]
    }
  ]
}
